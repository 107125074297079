<template>
	<div class="workerTraining_page">
		<BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
		<div class="module1">
			<div class="content flex">
				<div class="fl">
					<div class="title">{{ formData.trainingPolicyBackgroundName }}</div>
					<div class="text">
						{{ formData.trainingPolicyBackgroundIntroduce }}
					</div>
				</div>
				<div class="fr">
					<img :src="formData.trainingPolicyBackgroundImgUrl" alt="" />
				</div>
			</div>
		</div>
		<div class="module2">
			<CoreCourses
				:coreCourseTitle="formData.trainingJobName"
				:registrationCoreCourse="registrationCoreCourse"
				:coreCourses="coreCoursesData"
			></CoreCourses>
		</div>
		<div class="module3">
			<CourseTitle :title="formData.trainingObjectivesName" contText="">
			</CourseTitle>
			<div class="content trainingTarget">
				<ul class="display">
					<li
						v-for="(
							item, index
						) in formData.industrialWorkerTrainingObjectivesS"
						:key="index"
					>
						<div class="name">{{ item.trainingObjectivesName }}</div>
						<div class="text">
							{{ item.trainingObjectivesIntroduce }}
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="module4">
			<CourseTitle
				:title="formData.certificateStyleName"
				:contText="formData.certificateStyleIntroduce"
			>
			</CourseTitle>
			<div class="content ertificateStyle display">
				<img
					class="certificate_img"
					:src="formData.certificateStyleImgUrl"
					alt=""
				/>
			</div>
		</div>
		<div class="module5">
			<SceneTeaching
				:title="formData.wholeFaceName"
				:contText="formData.wholeFaceIntroduce"
				:faceTeaching="faceTeachingData"
			></SceneTeaching>
		</div>
		<div class="module6">
			<div class="">
				<CourseTitle
					:title="formData.goldMedalTeacherName"
					:contText="formData.goldMedalTeacherIntroduce"
				>
				</CourseTitle>
				<Lecturer :lecturerList="formData.teacherVOS"></Lecturer>
			</div>
		</div>
		<div class="module7">
			<CollegeWork
				:title="formData.studentWorksName"
				:contText="formData.studentWorksIntroduce"
				:studentsWork="formData.industrialWorkerStudentWorksS"
			></CollegeWork>
		</div>
		<div class="module8">
			<TeachingEnvironment
				:title="formData.siteTrainingBaseName"
				contText=""
				titleDescribe=""
				type="产业工人培训"
				:list="formData.homeTeachingEnvironmentVOMaps"
				:courseList="courseData"
			></TeachingEnvironment>
		</div>
		<div class="module9">
			<CourseTitle :title="'免费领取试听课程'"> </CourseTitle>
			<FreeCharge :status="12" :type="'产业工人培训'"></FreeCharge>
		</div>
	</div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from '../components/courseTitle.vue'
import CoreCourses from '@/components/coreCourses'
import SceneTeaching from '@/components/sceneTeaching'
import CollegeWork from '@/components/collegeWork'
import Lecturer from '@/components/lecturer'
import TeachingEnvironment from '@/components/teachingEnvironment'
import FreeCharge from '@/components/freeCharge'
export default {
	components: {
		BannerImg,
		CourseTitle,
		CoreCourses,
		SceneTeaching,
		CollegeWork,
		Lecturer,
		TeachingEnvironment,
		FreeCharge,
	},
	data() {
		return {
			formData: {},
			coreCoursesData: [],
			registrationCoreCourse: [],
			faceTeachingData: [],
			lecturerData: [],
			courseData: [],
		}
	},
	mounted() {
		this.init()
		document.documentElement.scrollTop = 0
	},
	methods: {
		//初始化数据
		init() {
			this.api.workerTrainingApi().then((res) => {
				this.formData = res.data
				let arr = []

				this.formData.industrialWorkerTrainingJobs.forEach((item) => {
					let obj = {
						id: item.id,
						orderNum: item.orderNum,
						status: item.status,
						coreCourseName: item.trainingJobName,
						coreCourseImgUrl: item.trainingJobImgUrl,
						coreCourseIntroduce: item.trainingJobIntroduce,
					}
					arr.push(obj)
				})
				this.registrationCoreCourse = arr
				this.coreCoursesData = this.registrationCoreCourse[0]
				let arr1 = []
				this.formData.industrialWorkerWholeFaces.forEach((item) => {
					let obj1 = {
						id: item.id,
						orderNum: item.orderNum,
						status: item.status,
						faceTeachingName: item.wholeFaceName,
						faceTeachingImgUrl: item.wholeFaceImgUrl,
					}
					arr1.push(obj1)
				})
				this.faceTeachingData = this.imgFormat(arr1, 3)

				this.formData.industrialWorkerStudentWorksS.forEach((item) => {
					item.studentWorksImgUrl = item.studentWorksImgUrl.split(',')
				})
				this.courseData = this.formData.homeTeachingEnvironmentVOMaps[0].value
				console.log(this.courseData, 11111111)
			})
		},
		//格式化图片
		imgFormat(dataList, num) {
			let newDataList = []
			let current = 0
			if (dataList && dataList.length > 0) {
				for (let i = 0; i <= dataList.length - 1; i++) {
					if (i % num !== 0 || i === 0) {
						if (!newDataList[current]) {
							newDataList.push([dataList[i]])
						} else {
							newDataList[current].push(dataList[i])
						}
					} else {
						current++
						newDataList.push([dataList[i]])
					}
				}
			}
			return (dataList = [...newDataList])
		},
	},
}
</script>
<style lang="scss" scoped>
.workerTraining_page {
	.module1 {
		height: 640px;
		background: url('./img/workerTraining/bg1.png') no-repeat;
		background-size: 100% 100%;
		.fl {
			margin-top: 214px;
			.title {
				font-size: 32px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #333333;
				line-height: 30px;
			}
			.text {
				width: 496px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 24px;
				margin-top: 36px;
			}
		}
		.fr {
			img {
				width: 700px;
				height: 410px;
				margin-top: 130px;
				margin-left: 10px;
			}
		}
	}
	.module3 {
		height: 510px;
		background: #fafafa;
		.trainingTarget {
			margin-top: 30px;
			ul {
				li:nth-child(1) {
					background: url('./img/workerTraining/01.png') no-repeat;
					background-size: 100% 100%;
				}
				li:nth-child(2) {
					background: url('./img/workerTraining/02.png') no-repeat;
					background-size: 100% 100%;
				}
				li:nth-child(3) {
					background: url('./img/workerTraining/03.png') no-repeat;
					background-size: 100% 100%;
				}
				li {
					width: 380px;
					height: 252px;
					margin: 0px 14px;
					.name {
						font-size: 20px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #ffffff;
						line-height: 20px;
						margin-top: 57px;
						margin-left: 20px;
					}
					.text {
						width: 272px;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 24px;
						margin-top: 50px;
						margin-left: 43px;
					}
				}
			}
		}
	}
	.module4 {
		height: 685px;
		background: url('../../../assets/img/course/bg19.png') no-repeat;
		background-size: 100% 100%;
		padding-top: 40px;
		.ertificateStyle {
			.certificate_img {
				width: 900px;
				height: 320px;
				margin: 0 auto;
				margin-top: 36px;
			}
		}
	}
	.module6 {
		background: #fafafa;
		height: 706px;
	}
	.module7 {
		background: #fff;
	}
}
</style>